

const ListGateway = () => import('@/views/gateway/List_Gateway')
const EditGateway = () => import('@/views/gateway/Edit_Gateway')
const DetailGateway = () => import('@/views/gateway/Detail_Gateway')
const WhitelistGateway = () => import('@/views/gateway/Whitelist_Gateway')

export default [
    {
        path: 'gateways',
        redirect: '/gateways/list',
        name: 'Gateways',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'list',
                name: 'Gateway List',
                component: ListGateway
            },
            {
                path: 'edit',
                name: 'Gateway New',
                component: EditGateway
            },
            {
                path: 'edit/:hashID',
                name: 'Gateway Edit',
                component: EditGateway
            },
            {
                path: 'detail/:hashID',
                name: 'Gateway Detail',
                component: DetailGateway
            },
            {
                path: 'whitelist/:serviceunitID',
                name: 'Gateway Whitelist',
                component: WhitelistGateway
            },
        ]
    },
]