

const TheDoorContainer = () => import('@/containers/TheDoorContainer')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const PasswordForget = () => import('@/views/pages/PasswordForget')
const PasswordReset = () => import('@/views/pages/PasswordReset')

export default [
    {
        path: '*',
        redirect: '/pages/404',
        component: TheDoorContainer,
        // name: 'Page404',
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: TheDoorContainer,
        children: [
            {
                path: '404',
                name: 'Page404',
                component: Page404
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500
            },
            {
                path: 'login',
                name: 'Login',
                component: Login
            },
            {
                path: 'register',
                name: 'Register',
                component: Register
            },
            {
                path: '/password/forget',
                name: 'Forget Password',
                component: PasswordForget
            },
            {
                path: '/password/reset',
                name: 'Reset Password',
                component: PasswordReset
            },
        ]
    },
]