/// 跳轉、錯誤處理、提示等方法放在這裡

import router from '@/router';
import Vue from 'vue';

/**
 * 錯誤訊息彈出工具
 */
export const tip = (msg) => {
  const vm = new Vue();
  vm.$bvToast.toast(msg, {
    title: 'Error',
    variant: 'danger',
  });
};

/**
 * 跳轉回首頁
 * 攜帶當前的頁面路由，登入完成後跳回原本頁面
 */
export const toLogin = () => {
  router.replace({
    name: 'Login',
    query: {
      redirect: router.currentRoute.fullPath,
    },
  });
};

/**
 * 跳轉到 403 錯誤頁面
 * 攜帶當前的頁面路由，點擊連結後跳回原本頁面
 */
export const to403Page = () => {
  router.replace({
    name: 'Page403',
  });
};

/**
 * 跳轉到 404 錯誤頁面
 * 攜帶當前的頁面路由，點擊連結後跳回原本頁面
 */
export const to404Page = () => {
  router.replace({
    name: 'Page404',
  });
};

/**
 * 跳轉到 500 錯誤頁面
 * 攜帶當前的頁面路由，點擊連結後跳回原本頁面
 */
export const to500Page = () => {
  router.replace({
    name: 'Page500',
  });
};
