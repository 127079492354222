import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */
export const api_Get_Customers = (query = undefined) => request('get', `/customers${setQueryString(query)}`);
export const api_Get_Customer_Detail = (params) => request('get', `/customers/${params.customerID}`);

export const api_Get_Customer_Records = (params, query = undefined) => request('get', `/customers/${params.customerID}/customer_records${setQueryString(query)}`);

export const api_Get_Customer_Reservations = (params, query = undefined) => request('get', `/customers/${params.customerID}/reservations${setQueryString(query)}`);

/** --------------------
 *  Admin
 *  ----------------- */
export const api_Create_Customer = (data) => request('post', '/admin/customers', data);
export const api_Update_Customer = (params, data) => request('put', `/admin/customers/${params.customerID}`, data);
export const api_Delete_Customer = (params) => request('delete', `/admin/customers/${params.customerID}`);
