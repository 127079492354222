

export default {

    /**
     *  date_picker: 取得 datepicker 專用的語系
     *  支援：en/zh/es/pt-br/fr/ru/de/it/cs，預設為zh
     */
    date_picker (language)
    {
        switch (language)
        {
            case 'zh-Hant-TW':  return 'zh'
            default:            return 'en'
        }
    },
}