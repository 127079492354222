import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  User
 *  ----------------- */

// export const api_Register_User = (data) => request('post', '/auth/register', data)
// export const api_Resetpasswd_User = (data) => request('put', '/me/resetpasswd', data)
export const api_Login_User = (data) => request('post', '/auth/login', data);
export const api_Logout_User = () => request('post', '/auth/logout');
export const api_Refresh_Token = () => request('post', '/auth/refresh');

export const api_Get_User_Profile = () => request('get', '/me');
export const api_Update_User_Profile = (data) => request('put', '/me', data);
export const api_Update_User_Language = (data) => request('put', '/me/lang', data);
export const api_Update_User_Preference = (data) => request('put', '/me/preference', data);

export const api_Get_Users_Simple = (query = undefined) => request('get', `/users${setQueryString(query)}`);

export const api_Get_Users = (query = undefined) => request('get', `/admin/users${setQueryString(query)}`);
export const api_Update_User = (params, data) => request('put', `/admin/users/${params.userID}`, data);
// export const api_Delete_User = (params) => request('delete', '/admin/users/' + params.userID)

/** --------------------
 *  Social Login
 *  ----------------- */

export const api_SocialLogin_Google = (params) => request('post', `/auth/login/google?code=${params.code}`);

/** --------------------
 *  Operation Log
 *  ----------------- */

export const api_Create_OPLog = (data) => request('post', '/oplogs', data);

// 列出操作記錄
export const api_Get_OPLog = (query = undefined) => request('get', `/admin/oplogs${setQueryString(query)}`);

// 列出操作記錄的數量統計
export const api_Get_OPLog_Summary = (query = undefined) => request('get', `/admin/oplogs/summary${setQueryString(query)}`);
