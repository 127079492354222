import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import { config as AgriConfig } from './prototype/config'
import AgriTools from './tools'
import store from './store'
import i18n from './i18n/i18n'

Vue.config.performance = true
Vue.use(CoreuiVue)


// vue-geolocation 瀏覽器 GPS
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);


// vue-lazyload - lazyloading
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    lazyComponent: true
  });


// Toastr 顯示通知工具
import Toastr from 'vue-toastr';                    // import Toastr
require('vue-toastr/src/vue-toastr.scss');          // import toastr scss file: need webpack sass-loader
Vue.use(Toastr, {
    defaultTimeout: 3000,                           // default timeout : 5000
    // defaultProgressBar: false,                      // default active : true
    // defaultProgressBarValue: 0,                     // default value : null (managed by JS timer)
    defaultType: "error",                           // default type : success 指定預設樣式型態，顏色、icon
    defaultPosition: "toast-top-center",            // default position: toast-top-right
    defaultCloseOnHover: false,                     // default close on hover: true
    // defaultStyle: { "background-color": "#e74b4b" },// default style of toast: {} (empty object)
    defaultClassNames: ["animated", "zoomInUp"]
}); // Register plugin
// 設定文件：http://s4l1h.github.io/vue-toastr/toast_options.html


// Time-Zone
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 
Vue.use(VueMoment, {
    moment,
})


// clipboard 複製到剪貼簿工具
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)


// Install BootstrapVue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)


// 設定 prototype
Vue.prototype.$config = AgriConfig;
Vue.prototype.$tools = AgriTools;

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    moment,
    icons,
    template  : '<App/>',
    components: {
        App
    },
    created ()
    {    
    }
})
