

const ListCustomer = () => import('@/views/customer/List_Customer')
const EditCustomer = () => import('@/views/customer/Edit_Customer')
const DetailCustomer = () => import('@/views/customer/Detail_Customer')
const ListCustomerRecord = () => import('@/views/customer/List_Customer_Record')
const EditCustomerRecord = () => import('@/views/customer/Edit_Customer_Record')
const ListReservation = () => import('@/views/customer/List_Reservation')
const EditReservation = () => import('@/views/customer/Edit_Reservation')
const ListShipment = () => import('@/views/customer/List_Shipment')
const EditShipment = () => import('@/views/customer/Edit_Shipment')
const ListSUID = () => import('@/views/customer/List_SUID')

export default [
    {
        path: 'customers',
        redirect: '/customers/list',
        name: 'Customers',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'list',
                name: 'Customer List',
                component: ListCustomer
            },
            {
                path: 'edit',
                name: 'Customer New',
                component: EditCustomer
            },
            {
                path: 'edit/:customerID',
                name: 'Customer Edit',
                component: EditCustomer
            },
            {
                path: 'detail/:customerID',
                name: 'Customer Detail',
                component: DetailCustomer
            },
            {
                path: 'records',
                name: 'Customer Record List',
                component: ListCustomerRecord
            },
            {
                path: 'records/edit/:recordID',
                name: 'Customer Record Edit',
                component: EditCustomerRecord
            },
            {
                path: 'reservations',
                name: 'Customer Reservation List',
                component: ListReservation
            },
            {
                path: ':customerID/reservations/edit',
                name: 'Customer Reservation New',
                component: EditReservation
            },
            {
                path: 'reservations/edit/:reservationID',
                name: 'Customer Reservation Edit',
                component: EditReservation
            },
            // {
            //     path: 'shipments',
            //     name: 'Customer Shipment List',
            //     component: ListCustomerShipment
            // },
            {
                path: ':customerID/shipments/edit',
                name: 'Customer Shipment New',
                component: EditShipment
            },
            {
                path: ':customerID/shipments/edit/:shipmentID',
                name: 'Customer Shipment Edit',
                component: EditShipment
            },
            {
                path: 'shipments',
                name: 'Customer Shipment List',
                component: ListShipment
            },
            {
                path: 'suids',
                name: 'Customer SUID List',
                component: ListSUID
            },
        ]
    },
]