import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */
export const api_Get_Reservations = (query = undefined) => request('get', `/reservations${setQueryString(query)}`);
export const api_Get_Reservation_Detail = (params) => request('get', `/reservations/${params.reservationID}`);
export const api_Get_Reservation_Shipments = (params, query = undefined) => request('get', `/reservations/${params.reservationID}/shipments${setQueryString(query)}`);

/** --------------------
 *  Admin
 *  ----------------- */

export const api_Create_Reservation = (data) => request('post', '/admin/reservations', data);
export const api_Update_Reservation = (params, data) => request('put', `/admin/reservations/${params.reservationID}`, data);
export const api_Delete_Reservation = (params) => request('delete', `/admin/reservations/${params.reservationID}`);
