export default {
  imgCover(url, imgSize = 'cover') {
    return {
      backgroundImage: `url(${url})`,
      backgroundSize: imgSize,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
  },

  classToggle(isOpen, style = '') {
    if (isOpen) return `fas fa-chevron-down ${style}`;
    return `fas fa-chevron-left ${style}`;
  },

  classToggle_Star(isStar, style = '') {
    if (isStar) return `fa fa-star ${style}`;
    return `fa fa-star-o ${style}`;
  },

  classIcon(type, style = '') {
    if (style && style != '') style = ` ${style}`;

    switch (type) {
      case 'go_top':
        return `fa fa-paw${style}`;
      case 'weather_forecast':
        return `wi wi-raindrop${style}`;
      case 'area':
        return `icon-puzzle${style}`;
      case 'density':
        return `icon-puzzle${style}`;

      case 'cropperiod':
        return `fa fa-tachometer${style}`;

      case 'gateway':
        return `fas fa-satellite-dish${style}`;
      case 'weatherbox':
        return `wi wi-solar-eclipse${style}`;
      case 'wand':
        return `fa fa-podcast${style}`;
      case 'camera':
        return `fa fa-camera${style}`;
      case 'actuator':
        return `fa fa-gamepad${style}`;
      case 'extra':
        return `wi wi-barometer${style}`;
      case 'accesspoint':
        return `fa fa-wifi${style}`;

      case 'is_refurbish':
        return `fas fa-recycle${style}`;
      case 'is_rent':
        return `far fa-clock${style}`;
      case 'is_nbiot':
        return `fas fa-project-diagram${style}`;
      case 'attention':
        return `fas fa-exclamation-triangle${style}`;
      case 'is_virtual':
        return `fas fa-drumstick-bite${style}`;

      case 'planning':
        return `fas fa-swatchbook${style}`;
      case 'wait4test':
        return `fas fa-vial${style}`;
      case 'inventory':
        return `fas fa-warehouse${style}`;
      case 'tally':
        return `fas fa-warehouse${style}`;
      case 'tosend':
        return `fas fa-dolly-flatbed${style}`;
      case 'shipping':
        return `fas fa-truck-moving${style}`;
      case 'active':
        return `fas fa-wifi${style}`;
      case 'investigating':
        return `fas fa-search-plus${style}`;
      case 'malfunction':
        return `fas fa-heart-broken${style}`;
      case 'waitcontact':
        return `fas fa-phone-volume${style}`;
      case 'waitback':
        return `fas fa-shipping-fast${style}`;
      case 'checking':
        return `fas fa-question${style}`;
      case 'inqueue':
        return `fab fa-stack-overflow${style}`;
      case 'repairing':
        return `fas fa-hammer${style}`;
      case 'resting':
        return `fas fa-bed${style}`;
      case 'abandon':
        return `fas fa-trash-alt${style}`;

      default:
        return `fa fa-map-pin${style}`;
    }
  },

  classSensorIcon(type, style = '') {
    if (style && style != '') style = ` ${style}`;

    switch (type) {
      case 'air_temperature':
        return `wi wi-thermometer${style}`;
      case 'air_humidity':
        return `wi wi-humidity${style}`;
      case 'air_temperature02':
        return `wi wi-thermometer${style}`;
      case 'air_humidity02':
        return `wi wi-humidity${style}`;
      case 'air_pressure':
        return `wi wi-hurricane${style}`;

      case 'air_co':
        return `icon-ghost${style}`;
      case 'air_co2':
        return `icon-ghost${style}`;
      case 'air_co2_temperature':
        return `wi wi-thermometer${style}`;
      case 'air_co2_humidity':
        return `wi wi-humidity${style}`;

      case 'air_nh3':
        return `icon-ghost${style}`;
      case 'air_h2s':
        return `icon-ghost${style}`;

      case 'air_pm25':
        return `wi wi-dust${style}`;
      case 'air_pm10':
        return `wi wi-dust${style}`;

      case 'wind_speed':
        return `wi wi-strong-wind${style}`;

      case 'rain_rate':
        return `wi wi-rain${style}`;
      case 'rainfalls':
        return `wi wi-rain${style}`;
      case 'rainfalls_1h':
        return `wi wi-rain${style}`;
      case 'rainfalls_3h':
        return `wi wi-rain${style}`;

      case 'water_depth':
        return `fa fa-anchor${style}`;

      case 'leaf_temperature':
        return `wi wi-thermometer${style}`;
      case 'leaf_humidity':
        return `wi wi-humidity${style}`;

      case 'solar_lux':
        return `wi wi-sunrise${style}`;
      case 'solar_radiation':
        return `wi wi-sunrise${style}`;
      case 'solar_par':
        return `wi wi-sunrise${style}`;
      case 'uvi':
        return `wi wi-solar-eclipse${style}`;
      case 'dew_point':
        return `wi wi-thermometer${style}`;

      case 'soil_temperature':
        return `fa fa-thermometer-empty${style}`;
      case 'soil_moisture':
        return `wi wi-humidity${style}`;
      case 'soil_conductivity':
        return `wi wi-lightning${style}`;
      case 'soil_ph':
        return `fa fa-flask${style}`;
      case 'bug_num':
        return `fa fa-bug${style}`;
      case 'daily_bugs':
        return `fa fa-bug${style}`;
      case 'mixture_temperature':
        return `wi wi-thermometer${style}`;

      case 'rssi':
        return `fa fa-signal${style}`;
      case 'snr':
        return `fa fa-signal${style}`;
      case 'lqi':
        return `fa fa-signal${style}`;
      case 'voltage':
        return `fa fa-battery-three-quarters${style}`;
      case 'solar_voltage':
        return `fa fa-battery-three-quarters${style}`;
      case 'battery_percentage':
        return `fa fa-battery-three-quarters${style}`;
      case 'voltage_to_battery_percentage':
        return `fa fa-battery-three-quarters${style}`;
      case 'inner_temperature':
        return `fa fa-thermometer-half${style}`;
      case 'rssi_to_signal_bar':
        return `fa fa-signal${style}`;
      case 'lqi_to_signal_bar':
        return `fa fa-signal${style}`;

      default:
        return `fas fa-rocket${style}`;
    }
  },

  classStressIcon(type, style = '') {
    if (style && style != '') style = ` ${style}`;

    switch (type) {
      case 'G001':
        return `wi wi-stars${style}`;
      case 'G002':
        return `fa fa-exclamation-triangle faa-flash animated${style}`;

      case 'A001':
      case 'S001':
        return `wi wi-stars${style}`;
      case 'A002':
      case 'S002':
        return `wi wi-thermometer${style}`;
      case 'A003':
      case 'S003':
        return `wi wi-thermometer${style}`;
      case 'A004':
      case 'S004':
        return `wi wi-sunrise${style}`;
      case 'A005':
      case 'S005':
        return `wi wi-sunrise${style}`;
      case 'A006':
      case 'S006':
        return `wi wi-strong-wind${style}`;
      case 'A007':
      case 'S007':
        return `fa fa-thermometer-empty${style}`;
      case 'A008':
      case 'S008':
        return `fa fa-thermometer-empty${style}`;
      case 'A009':
      case 'S009':
        return `wi wi-humidity${style}`;
      case 'A010':
      case 'S010':
        return `wi wi-humidity${style}`;
      case 'A011':
      case 'S011':
        return `wi wi-lightning${style}`;
      case 'A012':
      case 'S012':
        return `wi wi-lightning${style}`;
      case 'A013':
      case 'S013':
        return `fa fa-flask${style}`;
      case 'A014':
      case 'S014':
        return `fa fa-flask${style}`;

      case 'A015':
      case 'S015':
        return `wi wi-day-windy${style}`;

      case 'T001':
        return `wi wi-hurricane faa-flash animated${style}`;
      case 'T002':
        return `fa fa-exclamation-triangle faa-flash animated${style}`;

      default:
        return `fa fa-exclamation-triangle${style}`;
    }
  },
};
