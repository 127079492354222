<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        
        // 檢查瀏覽器
        this.$store.commit('user/check_Browser');

        // 監聽視窗大小變化
        const that = this
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth
                that.$store.commit('system/set_Screen_Width', window.screenWidth);
            })()
        }

        // 判斷不是 standalone app 才執行註冊 Service Worker
        if ( window.navigator.standalone === true )
        {
            console.log('display-mode is standalone');
        }
        // 註冊 Service Worker
        else if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register( process.env.BASE_URL + 'service-worker.js' ) // .register('/service-worker.js')
                .then( function () {

                    // console.log('Service Worker 註冊成功');
                    that.$store.commit('system/register_ServiceWorker', true);

                }).catch( function (error) {
                    // console.log('Service worker 註冊失敗:', error);
                    that.$store.commit('system/register_ServiceWorker', false);
                });
        } else {
            avalon.log('瀏覽器不支援 serviceWorker');
        }
    },
    watch: {
        '$route'(to, from) {

            /**
             * 草苗龜的 .vue 模板產生的順序：APP > Header > Profile > DefaultContainer
             * 故把 token 有無的判斷、最全域的資料撈取...等優先權最高的判斷寫在此處
             */
            //console.log('app updated');
            // 如果 token 不存在就導向 Login
            if (!localStorage.token) {

                //console.log( to )
                switch (to.name) {

                    // 不做動作的頁面
                    // case 'Report Page': break;
                    case 'Social Login Binding': break;

                    // 執行登出任務
                    case 'Forget Password': this.$store.commit('user/clean_Data'); break;
                    case 'Reset Password':  this.$store.commit('user/clean_Data'); break;
                    case 'Register':        this.$store.commit('user/clean_Data'); break;
                    case 'Login':           this.$store.commit('user/clean_Data'); break;

                    case 'Page403': this.$store.commit('user/clean_Data'); break;
                    case 'Page404': this.$store.commit('user/clean_Data'); break;
                    case 'Page500': this.$store.commit('user/clean_Data'); break;

                    default:
                        // 登入前紀錄使用者當時在哪個頁面
                        this.$store.commit('user/set_History', location.href)

                        this.$router.push({ name: 'Login' }); // 導向登入頁面
                        break;
                }
            
            } else {}
        }
    }
}
</script>

<style lang="scss">

    // Import Main styles for this application
    @import 'assets/scss/style';
    @import 'node_modules/bootstrap/scss/bootstrap';
    @import 'node_modules/bootstrap-vue/src/index.scss';
    
    // CoreUI Icon
    @import '~@coreui/icons/css/all.min.css';

    /* Import Font Awesome Icons Set */
    $fa-font-path: '~font-awesome/fonts/';
    @import '~font-awesome/scss/font-awesome.scss';
    @import '~font-awesome-animation/dist/font-awesome-animation.min.css';
    @import '~@fortawesome/fontawesome-free/css/all.min.css';

    /* Import Weather Icons Set */
    @import '~weather-icons2/css/weather-icons.min.css';
    @import '~weather-icons2/css/weather-icons-wind.min.css';
    
    // 台北黑體
    @import '~taipei-sans-tc/dist/Light/TaipeiSansTCBeta-Light.css';
    @import '~taipei-sans-tc/dist/Regular/TaipeiSansTCBeta-Regular.css';
    @import '~taipei-sans-tc/dist/Bold/TaipeiSansTCBeta-Bold.css';

</style>
