
export default {
    menu: {

    },

    n: {

    },

    u: {

    },

    sensor: {
    },

    chart: {

    },

    stress: {
    },

    date: {

    },

    wind: {

    },

    weather: {

    },

    err: {

    },

    other: {

    },
}