

const ListAccessPoint = () => import('@/views/accesspoint/List_AccessPoint')
const EditAccessPoint = () => import('@/views/accesspoint/Edit_AccessPoint')
const DetailAccessPoint = () => import('@/views/accesspoint/Detail_AccessPoint')

export default [
    {
        path: 'accesspoints',
        redirect: '/accesspoints/list',
        name: 'AccessPoints',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'list',
                name: 'AccessPoint List',
                component: ListAccessPoint
            },
            {
                path: 'edit',
                name: 'AccessPoint New',
                component: EditAccessPoint
            },
            {
                path: 'edit/:hashID',
                name: 'AccessPoint Edit',
                component: EditAccessPoint
            },
            {
                path: 'detail/:hashID',
                name: 'AccessPoint Detail',
                component: DetailAccessPoint
            },
        ]
    },
]