

const CustomerShipment = () => import('@/views/print/CustomerShipment')

export default [
    {
        path: '/print',
        redirect: '/pages/404',
        name: 'Print',
        component: {
            render (c) { return c('router-view') }  // 無套用 container
        },
        children: [
            {
                path: 'customer_shipment',
                name: 'Customer Shipment',
                component: CustomerShipment
            },
        ]
    },
    {
        path: '/public',
        redirect: '/public/report',
        name: 'Public',
        component: {
            render (c) { return c('router-view') }  // 無套用 container
        },
        children: [
            // {
            //     path: 'report',
            //     name: 'Report',
            //     component: Page404
            // },
        ]
    },
]