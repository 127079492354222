export default {
  menu: {
    home: '首頁',
    projects: '專案列表',
    project_summary: '專案總覽',
    history: '歷史資料',
    params_comparison: '參數對照',
    mix_chart: '混和圖表',
    comparison: '對照',
    links: '連結',
    summary: '摘要',
    updated: '更新',
    refresh_data: '更新資訊',
    select: '選擇',
    analysis: '分析',
    prediction: '預測',
    management: '管理',
    user: '使用者',
    import: '匯入',
    export: '匯出',
    show: '顯示',
    hide: '隱藏',
    create: '新增',
    upload: '上傳',
    download: '下載',
    message: '訊息',
    language: '語言',
    login: '登入',
    logout: '登出',
    register: '註冊',
    editting: '編輯',
    setting: '設定',
    status: '狀態',
    attribute: '屬性',
    authority: '權限',
    inquire: '查詢',
    close: '關閉',
    email: '電子信箱',
    password: '密碼',
    forgot_password: '忘記密碼',
    reset_password: '重設密碼',
    cancel: '取消',
    confirm: '確認',
    delete: '刪除',
    account: '帳號',
    user_name: '使用者名稱',
    user_profile: '使用者資料',
    upload_avatar: '上傳頭像',
    upload_image: '上傳圖片',
    select_image: '選擇圖片',
    save_changes: '儲存變更',
    last_name: '姓氏',
    first_name: '名字',
    phone: '電話',
    address: '地址',
    location: '地區',
    receive_early_warning_email: '接收預警通知信',
    accept_the_terms_and_use: '我同意使用者條款',
    remember_me: '記住我',
    company_job: '職稱 / 公司',
    service_level: '服務等級',
    start_date: '開始日期',
    end_date: '結束日期',
    manufacture_date: '製造日期',
    select_date: '選擇日期',
    select_date_range: '選擇日期範圍',
    time_now: '現在',
    clean: '清除',
    selected: '已選擇',
    deselect: '取消選擇',
    range: '範圍',
    date_previous: '最近',
    date_next: '未來',
    timezone: '時區',
    participants: '參與者',
    role: '身分',
    add_project_member: '新增專案成員',
    detail: '詳細資訊',
    show_detail: '顯示詳細資訊',
    related_search: '相關搜尋',
    search: '搜尋',
    search_result: '搜尋結果',
    search_count: '搜尋筆數',
    project_information: '專案資訊',
    cropping_season_information: '期作資訊',
    check_dashboard: '查看即時儀錶板',
    warning_alerts: '預警資訊',
    warning_history: '預警歷史紀錄',
    system_information: '系統資訊',
    device_information: '裝置資訊',
    go_top: '回頂部',
    toolbox: '編輯工具',
    cover: '封面',
    logo: 'LOGO',
    picture: '圖片',
    project: '專案',
    name: '名稱',
    description: '描述',
    type: '類別',
    admin_note: '管理員備註',
    admin_tools: '管理員工具',
    subordinate_management: '附屬功能管理',
    project_management: '專案管理',
    cropping_season_management: '期作管理',
    device_management: '裝置管理',
    custom_alert_rule_management: '自訂規則管理',
    new_project: '建立新專案',
    new_cropping_season: '建立新期作',
    clone_as_new_cropping_season: '複製為新期作',
    clone: '複製',
    copy: '複製',
    copy_link: '複製連結',
    copy_report_link: '複製報告連結',
    copy_to_clipboard: '複製到剪貼簿',
    delete_cropping_season: '刪除期作',
    done_cropping_season: '將此日期設定為期作完成日',
    add_device_to_the_cropping_season: '新增裝置到期作',
    remove_device_from_the_cropping_season: '從此期作移除裝置',
    devices_in_cropping_season: '此期作已綁定的裝置',
    unfinished: '未完成',
    finished: '已完成',
    abandoned: '已棄用',
    deleted: '已刪除',
    no_data: '無資料',
    no_data_to_download: '無資料可供下載',
    no_description: '無描述',
    no_content: '無內容',
    instructions: '說明',
    op_instructions: '操作說明',
    filter_tools: '篩選工具',
    map_instructions_rotate_laptop: 'Shift + 游標拖動  可旋轉、縮放地圖',
    map_instructions_rotate_tablet: '使用兩隻手指手勢旋轉、縮放地圖',
    map_instructions_zoom_in: '放大地圖',
    map_instructions_zoom_out: '縮小地圖',
    map_instructions_zoom_reset: '重置地圖縮放與位置',
    map_instructions_rotate_reset: '重置地圖旋轉',
    project_area: '專案面積',
    field_area: '面積',
    planting_density: '栽培密度',
    limit: '上限',
    print: '列印',
    new_comment: '新增評論',
    comment: '評論',
    reply: '回覆',
    edited: '已編輯',
    register_app_line: '註冊 Line 通知',
    linked: '已連結',
    group: '群組',
    setting_info: '設定說明',

    observe_time: '觀察時間',
    weather_forecast: '氣象預報',
    planting_day: '開始栽種日',
    planting_period: '栽種期間',
    days_after_planting: '種植天數',
    current_growth_stage: '生長階段',
    days_to_next_stage: '進入下一階段，還有',
    days_left: '剩餘天數',
    crop_growth_progress: '作物生長進度',
    agri_science_reports: '生產誌摘要報告',
    weather_and_crop_forecast: '未來天氣與作物概況',
    official_warning: '官方警報',
    suggested_activities: '建議行為',
    suggested_preventions: '防治建議',
    stress: '逆境狀態',
    farming_activity: '農務工作',
    disease_pest_control: '病蟲害處理',
    crop_traits: '性狀紀錄',
    expert_consult: '專家諮詢',
    leave_a_message: '留言',
    yield_prediction: '產量預測',
    data_count: '資料筆數',
    data_table: '資料表',
    update_frequency: '更新頻率',
    per: '每',
    per_page: '每頁數量',

    daily_change: '變化表',
    statical_result: '統計表',
    parameter_choose: '資料呈現',
    accumulation: '累積',
    total: '總計',
    average: '平均',
    midian: '中位數',
    standard_deviation: '標準差',
    value: '數值',

    weather_report: '氣候分析報告',
    analysis_report: '分析報告',
    analysis_tools: '分析工具',
    crop_records: '種植履歷',
    agrinote: '阿龜誌',
    field_notes: '田間紀錄',
    time_lapse_photo: '縮時攝影',
    time_lapse_camera: '縮時攝影機',
    adversity_weather: '逆境氣候',
    environmental_data: '環境氣候資料',
    soil_data: '土壤資料',
    crop_growth_period: '作物生長期',
    sensor_parameter: '感測器參數',

    microclimate_analysis: '農業微氣候解決方案',
    agri_dashboard: '阿龜儀表版',
    agriweather: '阿龜微氣候',
    agri_weather_station: '阿龜氣象站',
    system_info: '系統洽詢',
    field_construction_map: '場域架構圖',
    preview: '預覽',
    coordinate: '座標',
    map: '地圖',
    map_get_current: '取得當下地圖設定',
    map_toolbox: '地圖工具箱',
    map_coordinate: '地圖中心座標',
    mouse_coordinate: '地圖上的滑鼠座標',
    map_boundary: '地圖邊界',
    longitude: '經度',
    latitude: '緯度',
    map_zoom: '地圖縮放',
    online_map_boundary_editor: '線上地圖邊界編輯工具',
    map_satellite: '衛星地圖',
    map_street: '街道地圖',
    add_to_home_screen: '新增 APP 到主畫面',
    get_geo_location: '取得當下經緯度資訊',
    max: '最大值',
    min: '最小值',
    alert_filter: '警示次數篩選',
    calculation_standard: '計算標準',
    no_alert_data: '無警示資料',
    alert_message: '警示訊息',
    alert_rule: '警示規則',
    alert_threshold: '發生幾次後通知',
    management_tool: '管理工具',
    previous: '前一筆',
    next: '後一筆',
    little_doctor_agri: '阿龜小博士',
    feedback_to_agri: '回饋意見給阿龜',

    portal: '傳送門',
    is_refurbish: '整新',
    is_virtual: '虛擬',
    is_rent: '租用',
    is_nbiot: 'NB-IoT',
    attention: '注意',
  },

  n: {
    all: '全部',
    other: '其他',
    device: '裝置',
    weather_box: '氣象站',
    sensor: '感測器',
    gateway: '閘道器',
    weatherbox: '氣象站',
    wand: '阿龜感測器',
    camera: '縮時攝影',
    actuator: '控制器',
    extra: '其他裝置',
    accesspoint: '無線熱點',

    temperature: '溫度',
    accumulatedTemperature: '積溫',
    humidity: '相對溼度',
    rainfall: '降雨量',
    illuminance: '光照強度',
    atmospheric_pressure: '大氣氣壓',
    soil_temperature: '土壤溫度',
    soil_moisture: '土壤含水量',
    soil_conductivity: '土壤電導度',
    battery: '電量',
    wind_speed: '風速',
    wind_direction: '風向',
    co2_concentration: '二氧化碳濃度',
    pH_value: '酸鹼值',

    cropping_season: '期作',
    crop: '作物',
    variety: '品種',
    farm: '農場',
    field: '田區',
    worker: '工作者',

    weight: '重量',
    quality: '品質',

    plants: '株數',
    photos: '照片',

    admin: '管理者',
    beehive: '蜂巢',
    project_owner: '專案擁有者',
    project_member: '專案成員',
    researcher: '研究者',
    guest: '訪客',
  },

  status: {
    user: {
      enable: '已啟用',
      disabled: '已禁用',
      deleted: '已刪除',
    },

    record_action: {
      manufacture: '製造',
      assemble: '組裝',
      fix: '維修完成',
      ready: '入庫',
      reborn: '整新入庫',
      picking: '撿貨',
      package: '打包',
      ship: '出貨',
      activate: '啟用',
      report: '回報',
      broken: '壞掉',
      takeback: '帶回',
      sendback: '要寄回',
      recall: '聯繫收件',
      withdraw: '收回',
      pushqueue: '送修',
      repair: '維修',
      hotfix: '現場維修',
      pend: '擱置',
      terminate: '廢棄',

      warn: '注意',
      upgrade: '升級 ',
      record: '紀錄',
      functest: '測試',
      other: '其他',
    },

    device_status: {
      planning: '備料',
      wait4test: '待測試',
      inventory: '在庫',
      tally: '理貨中',
      tosend: '待運送',
      shipping: '出貨',
      active: '運作中',
      investigating: '調查中',
      malfunction: '故障',
      waitcontact: '待聯繫',
      waitback: '待回收',
      checking: '檢測',
      inqueue: '待維修',
      repairing: '維修',
      resting: '休息',
      abandon: '棄置',
    },
  },

  report: {
    type: {
      monthly: '月報',
      period: '履歷',
    },
    ui: {
      overview: '總覽',
      air: '空氣',
      sun: '陽光',
      water: '水',
      comment: '評論',
      alert_calendar: '警示月曆',
      daily: '每日平均',
      chart: '圖表',
      kchart: 'K 線圖(蠟燭圖)',
    },
  },

  u: {
    empty: '未定義單位',

    unit: '單位',
    date: '日期',
    period: '期間',
    years: '年',
    years_ago: '年前',
    months: '月',
    months_ago: '月前',
    weeks: '週',
    weeks_ago: '週前',
    day: '天',
    days: '天',
    days_ago: '天前',
    hours: '小時',
    hours_ago: '小時前',
    minutes: '分鐘',
    minutes_ago: '分鐘前',
    seconds: '秒',
    seconds_ago: '秒前',
    time: '時間',
    time_s: '次',

    per_hour: '每小時',
    per_day: '每日',
    per_month: '每月',
    per_year: '每年',
    past_years: '歷年',

    m: '公尺',
    km: '公里',
    cm: '公分',
    mm: '毫米',
    taiwanese_foot: '台尺',
    feet: '英呎',

    kg: '公斤',

    m2: '平方公尺',
    ha: '公頃',
    hun: '分',
    kah: '甲',
    acre: '英畝',

    pictures: '張',

    // sensor unit
    temperature_c: '℃',
    humidity: '%',
    moisture: '%',
    percentage: '%',
    ppm: 'ppm',
    air_pm: '微克/立方公尺', // 空氣細懸浮微粒濃度 μg/m<sup>3</sup>
    pressure: 'hPa',
    speed_wind: 'm/s',
    degree: 'degree',
    rate_rain: 'mm/hr',
    mm: 'mm',
    radiation: 'W/m<sup>2</sup>',
    lux: 'lux',
    par: 'μmol/m<sup>2</sup>s',
    uvi: 'UVI',
    conductivity: 'ds/m',
    ph: '',
    voltage: 'V',
    dbm: 'dBm',
    db: 'dB',
  },

  sensor: {
    default: '',
    u_default: '',

    air_temperature: '空氣溫度',
    u_air_temperature: '℃',
    air_humidity: '空氣溼度',
    u_air_humidity: '%',
    air_temperature02: '空氣氣溫 02',
    u_air_temperature02: '℃',
    air_humidity02: '空氣溼度 02',
    u_air_humidity02: '%',

    mixture_temperature: '混合物溫度',
    u_mixture_temperature: '℃',

    air_co2_temperature: '空氣二氧化碳溫度',
    u_air_co2_temperature: '℃',
    air_co2_humidity: '空氣二氧化碳溼度',
    u_air_co2_humidity: '%',

    air_co: '空氣一氧化碳濃度',
    u_air_co: 'ppm',
    air_co2: '空氣二氧化碳濃度',
    u_air_co2: 'ppm',
    air_nh3: '空氣氨氣濃度',
    u_air_nh3: 'ppm',
    air_h2s: '空氣硫化氫濃度',
    u_air_h2s: 'ppm',

    air_pm25: 'PM 2.5',
    u_air_pm25: '微克/立方公尺', // 空氣細懸浮微粒濃度 μg/m<sup>3</sup>
    air_pm10: 'PM 10',
    u_air_pm10: '微克/立方公尺', // 空氣細懸浮微粒濃度 μg/m<sup>3</sup>

    air_pressure: '氣壓',
    u_air_pressure: 'hPa',
    wind_speed: '風速',
    u_wind_speed: 'm/s',
    wind_direction: '風向',
    u_wind_direction: 'degree',
    rain_rate: '降雨速率',
    u_rain_rate: 'mm/hr',
    rain_rate_raw: '截取降雨速率',
    u_rain_rate_raw: 'mm/hr',
    rainfalls: '日降雨量',
    u_rainfalls: 'mm',

    water_depth: '水位深度',
    u_water_depth: 'mm',

    leaf_temperature: '葉面溫度',
    u_leaf_temperature: '℃',
    leaf_humidity: '葉面濕度',
    u_leaf_humidity: '%',

    dew_point: '露點',
    u_dew_point: '℃',
    solar_radiation: '光照',
    u_solar_radiation: 'W/m<sup>2</sup>',
    solar_lux: '太陽光照',
    u_solar_lux: 'lux',
    solar_par: '光合作用有效光 PAR',
    u_solar_par: 'μmol/m<sup>2</sup>s',
    uvi: '紫外線指數',
    u_uvi: 'UVI',

    soil_temperature: '土壤溫度',
    u_soil_temperature: '℃',
    soil_moisture: '土壤含水量',
    u_soil_moisture: '%',
    soil_conductivity: '土壤電導度',
    u_soil_conductivity: 'ds/m',
    soil_conductivity_pore: '土壤電導度-PORE',
    u_soil_conductivity_pore: 'ds/m',
    soil_ph: '土壤酸鹼值',
    u_soil_ph: '',

    voltage: '電壓',
    u_voltage: 'V',
    solar_voltage: '太陽能板電壓',
    u_solar_voltage: 'V',
    bug_num: '蟲罐捕捉數量',
    u_bug_num: '',
    daily_bugs: '日累積捕蟲數量',
    u_daily_bugs: '',
    battery_percentage: '電量百分比',
    u_battery_percentage: '%',
    voltage_to_battery_percentage: '電量百分比',
    u_voltage_to_battery_percentage: '%',
    inner_temperature: '裝置內部溫度',
    u_inner_temperature: '℃',
    rssi: '訊號強度',
    u_rssi: 'dBm',
    snr: '訊號雜訊比',
    u_snr: 'dB',
    rssi_to_signal_bar: '訊號強度',
    u_rssi_to_signal_bar: '',
    lqi: '訊號品質指標',
    u_lqi: '',
    lqi_to_signal_bar: '訊號品質指標',
    u_lqi_to_signal_bar: '',

    // WA
    rainfalls_1h: '一小時累積雨量',
    u_rainfalls_1h: 'mm',
    rainfalls_3h: '三小時累積雨量',
    u_rainfalls_3h: 'mm',

    /**
     * report
     * */
    // 舊版(2019.12以前) report keyword
    air_temperature_max: '空氣溫度 最大值',
    u_air_temperature_max: '℃',
    air_temperature_min: '空氣溫度 最低值',
    u_air_temperature_min: '℃',
    air_temperature_mean: '空氣溫度 平均值',
    u_air_temperature_mean: '℃',
    solar_hour: '日照時數',
    u_solar_hour: '小時',
    solar_mj: '累積日照量',
    u_solar_mj: 'MJ',

    // report - air:
    // 氣溫圖表：
    daily_mean_temp: '日平均溫度',
    u_daily_mean_temp: '℃',
    daily_high_temp: '日最高溫度',
    u_daily_high_temp: '℃',
    daily_low_temp: '日最低溫度',
    u_daily_low_temp: '℃',
    // 氣溫統計值：
    avg_daily_high_temp: '平均日最高溫',
    u_avg_daily_high_temp: '℃',
    avg_daily_temp: '平均日溫度',
    u_avg_daily_temp: '℃',
    avg_daily_low_temp: '平均日最低溫',
    u_avg_daily_low_temp: '℃',
    avg_daily_diff_temp: '平均日溫差',
    u_avg_daily_diff_temp: '℃',
    // 濕度圖表：
    daily_mean_humidity: '日平均濕度',
    u_daily_mean_humidity: '%',
    daily_high_humidity: '日最高濕度',
    u_daily_high_humidity: '%',
    daily_low_humidity: '日最低濕度',
    u_daily_low_humidity: '%',
    // 濕度統計值：
    avg_daily_high_humidity: '平均日最高濕度',
    u_avg_daily_high_humidity: '%',
    avg_daily_humidity: '平均日濕度',
    u_avg_daily_humidity: '%',
    avg_daily_low_humidity: '平均日最低濕度',
    u_avg_daily_low_humidity: '%',
    avg_daily_diff_humidity: '平均日夜差',
    u_avg_daily_diff_humidity: '%',

    // report - sun:
    // 日照圖表：
    daily_sun_hours: '日照時數',
    u_daily_sun_hours: '小時',
    daily_solar_radiation: '日照量',
    u_daily_solar_radiation: 'MJ',
    // 日照統計值：
    acc_daily_sun_hours: '累積日照時數',
    u_acc_daily_sun_hours: '小時',
    acc_daily_solar_radiation: '累積日照量',
    u_acc_daily_solar_radiation: 'MJ',
    avg_daily_sun_hours: '平均日照時數',
    u_avg_daily_sun_hours: '小時',
    avg_daily_solar_radiation: '平均日照量',
    u_avg_daily_solar_radiation: 'MJ',

    // report - water:
    // 降雨圖表：
    daily_precipitation: '日降雨量',
    u_daily_precipitation: 'mm',
    // 降雨統計值：
    acc_rainfall: '累積降雨',
    u_acc_rainfall: 'mm',
    rainy_days: '降雨日數',
    u_rainy_days: '天',
    days_of_heavy_rain: '豪大雨日數',
    u_days_of_heavy_rain: '天',

    // report - sensors:
    // 土溫圖表：
    daily_mean_soil_temp: '日平均土壤溫度',
    u_daily_mean_soil_temp: '℃',
    daily_high_soil_temp: '日最高土壤溫度',
    u_daily_high_soil_temp: '℃',
    daily_low_soil_temp: '日最低土壤溫度',
    u_daily_low_soil_temp: '℃',
    // 土溫統計值：(土溫與氣溫計算規則相似，故 key 以相似規則命名)
    avg_daily_high_soil_temp: '平均最高土壤溫度',
    u_avg_daily_high_soil_temp: '℃',
    avg_daily_soil_temp: '平均土壤溫度',
    u_avg_daily_soil_temp: '℃',
    avg_daily_low_soil_temp: '平均最低土壤溫度',
    u_avg_daily_low_soil_temp: '℃',
    avg_daily_diff_soil_temp: '平均土壤溫度差',
    u_avg_daily_diff_soil_temp: '℃',
    // 土濕圖表(K 線)
    daily_start_soil_moisture: '土壤含水率 起始值',
    u_daily_start_soil_moisture: '%',
    daily_high_soil_moisture: '土壤含水率 日最高',
    u_daily_high_soil_moisture: '%',
    daily_low_soil_moisture: '土壤含水率 日最低',
    u_daily_low_soil_moisture: '%',
    daily_end_soil_moisture: '土壤含水率 結束值',
    u_daily_end_soil_moisture: '%',
    // 土濕統計值：
    avg_daily_soil_moisture: '平均土壤含水率',
    u_avg_daily_soil_moisture: '%',
    avg_daily_high_soil_moisture: '平均最高土壤含水率',
    u_avg_daily_high_soil_moisture: '%',
    avg_daily_low_soil_moisture: '平均最低土壤含水率',
    u_avg_daily_low_soil_moisture: '%',
    // 土EC圖表(K 線)
    daily_start_soil_conductivity: '土壤電導度 起始值',
    u_daily_start_soil_conductivity: 'ds/m',
    daily_high_soil_conductivity: '土壤電導度 日最高',
    u_daily_high_soil_conductivity: 'ds/m',
    daily_low_soil_conductivity: '土壤電導度 日最低',
    u_daily_low_soil_conductivity: 'ds/m',
    daily_end_soil_conductivity: '土壤電導度 結束值',
    u_daily_end_soil_conductivity: 'ds/m',
    // 土EC統計值：
    avg_daily_soil_conductivity: '平均土壤電導度',
    u_avg_daily_soil_conductivity: 'ds/m',
    avg_daily_high_soil_conductivity: '平均最高土壤電導度',
    u_avg_daily_high_soil_conductivity: 'ds/m',
    avg_daily_low_soil_conductivity: '平均最低土壤電導度',
    u_avg_daily_low_soil_conductivity: 'ds/m',
    // 殘差值
    soil_conductivity_residual: '土壤電導度殘差值',
    u_soil_conductivity_residual: 'ds/m',
    // 土 pH 圖表(K 線)
    daily_start_soil_ph: '土壤pH 起始值',
    u_daily_start_soil_ph: '',
    daily_high_soil_ph: '土壤pH 日最高',
    u_daily_high_soil_ph: '',
    daily_low_soil_ph: '土壤pH 日最低',
    u_daily_low_soil_ph: '',
    daily_end_soil_ph: '土壤pH 結束值',
    u_daily_end_soil_ph: '',
    // 土 pH 統計值：
    avg_daily_soil_ph: '平均土壤pH',
    u_avg_daily_soil_ph: '',
    avg_daily_high_soil_ph: '平均最高土壤pH',
    u_avg_daily_high_soil_ph: '',
    avg_daily_low_soil_ph: '平均最低土壤pH',
    u_avg_daily_low_soil_ph: '',
  },

  chart: {
    no_rain: '無雨',
    drizzle: '毛毛雨',
    rain: '有雨',
    sprinkle: '小雨',
    moderate_rain: '中雨',
    heavy_rain: '大雨',
    extremely_heavy_rain: '豪雨',
    torrential_rain: '大豪雨',
    extremely_torrential_rain: '超大豪雨',

    low_impact: '低影響',
    medium_impact: '中影響',
    high_impact: '高影響',

    wind_strong: '強風',
    wind_moderate: '中度',
    wind_light: '微風',
    wind_spary_interference: '噴藥干擾',

    // rssi & snr
    signal_strong: '極好',
    signal_good: '良好',
    signal_ok: '普通',
    signal_lowest_limit: '最低底線',

    air_pm_unhealthy: '對所有族群不健康',
  },

  stress: {
    0: '正常',
    1: '最適區間',
    2: '異常',
    3: '高溫障礙',
    4: '低溫障礙',
    5: '光度過強',
    6: '光度不足',
    7: '風速過高',
    8: '土溫過高',
    9: '土溫過低',
    10: '土壤水份過多',
    11: '土壤水份過少',
    12: '土壤電導度過高',
    13: '土壤電導度過低',
    14: '土壤酸鹼值過高',
    15: '土壤酸鹼值過低',

    G001: '正常',
    G002: '異常',

    A001: '最適區間',
    A002: '高溫注意',
    A003: '低溫注意',
    A004: '強光注意',
    A005: '弱光注意',
    A006: '強風注意',
    A007: '土溫過高注意',
    A008: '土溫過低注意',
    A009: '水份過多注意',
    A010: '水份過少注意',
    A011: '鹽份過高注意',
    A012: '鹽份過低注意',
    A013: '土壤過鹼注意',
    A014: '土壤過酸注意',
    A015: '焚風注意',

    S001: '最適區間',
    S002: '高溫障礙',
    S003: '低溫障礙',
    S004: '光度過強',
    S005: '光度不足',
    S006: '風速過高',
    S007: '土溫過高',
    S008: '土溫過低',
    S009: '土壤水份過多',
    S010: '土壤水份過少',
    S011: '土壤電導度過高',
    S012: '土壤電導度過低',
    S013: '土壤酸鹼值過高',
    S014: '土壤酸鹼值過低',
    S015: '焚風',

    T001: '官方颱風警報',
    T002: '官方天氣警特報',
  },

  date: {
    Mon: '一',
    Tue: '二',
    Wed: '三',
    Thu: '四',
    Fri: '五',
    Sat: '六',
    Sun: '日',
    week: '星期',

    months: {
      January: '1 月',
      February: '2 月',
      March: '3 月',
      April: '4 月',
      May: '5 月',
      June: '6 月',
      July: '7 月',
      August: '8 月',
      September: '9 月',
      October: '10 月',
      November: '11 月',
      December: '12 月',
    },

    solar_terms: '節氣',
    solar_term_list: {
      0: '小寒',
      1: '大寒',
      2: '立春',
      3: '雨水',
      4: '驚蟄',
      5: '春分',
      6: '清明',
      7: '穀雨',
      8: '立夏',
      9: '小滿',
      10: '芒種',
      11: '夏至',
      12: '小暑',
      13: '大暑',
      14: '立秋',
      15: '處暑',
      16: '白露',
      17: '秋分',
      18: '寒露',
      19: '霜降',
      20: '立冬',
      21: '小雪',
      22: '大雪',
      23: '冬至',
    },
  },

  wind: {
    W: '西',
    WSW: '西南西',
    SW: '南西',
    SSW: '南南西',
    S: '南',
    SSE: '南南東',
    SE: '南東',
    ESE: '東南東',
    E: '東',
    ENE: '東北東',
    NE: '北東',
    NNE: '北北東',
    N: '北',
    NNW: '北北西',
    NW: '北西',
    WNW: '西北西',
    0: '',
  },

  weather: {
    0: '龍捲風',
    1: '熱帶風暴',
    2: '颶風',
    3: '強雷陣雨',
    4: '雷陣雨',
    5: '混合雨雪',
    6: '混合雨雪',
    7: '混合雨雪',
    8: '冰凍小雨',
    9: '細雨',
    10: '凍雨',
    11: '陣雨',
    12: '陣雨',
    13: '飄雪',
    14: '陣雪',
    15: '吹雪',
    16: '下雪',
    17: '冰雹',
    18: '雨雪',
    19: '多塵',
    20: '多霧',
    21: '陰霾',
    22: '多煙',
    23: '狂風大作',
    24: '有風',
    25: '冷',
    26: '多雲',
    27: '晴時多雲',
    28: '晴時多雲',
    29: '晴時多雲',
    30: '晴時多雲',
    31: '清晰的',
    32: '晴朗',
    33: '晴朗',
    34: '晴朗',
    35: '雨和冰雹',
    36: '炎熱',
    37: '雷陣雨',
    38: '零星雷陣雨',
    39: '零星雷陣雨',
    40: '零星雷陣雨',
    41: '大雪',
    42: '零星陣雪',
    43: '大雪',
    44: '多雲',
    45: '雷陣雨',
    46: '陣雪',
    47: '雷陣雨',
    3200: '無資料',
  },

  cgp: {
    crown_planting: '採苗及栽植期',
    foliage_and_root_growth: '植株發育期',
    forcing: '催花期',
    flowering: '開花期',
    fruiting: '果實發育期',
    harvesting: '果實成熟期',

    seedling: '幼苗期',
    leaf_development: '輪生初中期',
    stem_elongation: '輪生後期',
    tasseling_stage: '抽雄期',
    silking_stage: '吐絲期',
    milk_stage: '乳熟期',

    vegetative_stage: '營養生長期',
    bbch_7_soybean: '莢及籽粒生長',
    bbch_8_soybean: '夾及籽粒成熟',
  },

  err: {},

  tooltip: {
    data_set_align_bounding_left: '對齊左邊 y 軸的資料集',
    data_set_align_bounding_right: '對齊右邊 y 軸的資料集',
    show_data_toggle: '資料顯示/隱藏按鈕，用來暫時隱藏不想看的資料',
    zoom_data_slider: '資料時間軸縮放，預設顯示全部資料時間，可自行拉動調整觀看時間範圍',
    min_value_of_the_first_data: '第一個資料集的的最小值',
    max_value_of_the_first_data: '第一個資料集的的最大值',
    hover_xaxis_show_tooltip: '滑鼠移動到 x 軸，出現該時間的所有資料集的數值與單位',
    yaxis_bounding_of_the_first_data: '左邊為第一個資料集(和其他相同單位的資料集)的 y 軸數值邊界',
    yaxis_bounding_of_all_data: '右邊為所有資料集的 y 軸數值邊界',
  },

  other: {
    toastr_welcome: '歡迎使用阿龜微氣候平台 !',
    toastr_hi: '嗨, ',

    total_suffered: '目前共經歷了',
    join_agriweather: '加入阿龜微氣候',
    register_now: '立刻註冊',
    log_in_to_your_account_by_email: '請以電子信箱登入',
    enter_the_email_address_of_your_account: '輸入註冊帳號的電子信箱',
    enter_the_password: '輸入原密碼',
    enter_the_new_password: '輸入新密碼',
    enter_the_new_password_again: '再次輸入新密碼',
    enter_the_password_again: '再次輸入密碼',
    send_reset_link_to_the_email: '寄送重設連結至信箱',
    create_account: '建立帳號',
    have_an_agri_account: '已經有阿龜帳號 ?',
    go_to_login_page: '前往登入頁面 !',
    input_required: '* 為必填項目',
    phone_placeholder: '範例：0912-345678, (02)2654321',
    company_placeholder: '範例：顧問 / XXX農場',
    format_supported: '支援格式',
    location_description: '簡單的位置描述，範例：高雄大樹區',

    password_info_old: '請輸入舊密碼',
    password_info_old_compare: '輸入的新舊密碼不可一樣',
    password_info_format: '密碼格式：6 ~ 20 個字元，其中必需包含英文大寫、英文小寫、數字至少各一個',
    password_info_new_compare: '再次輸入的新密碼不對',

    the_password_reset_email_has_been_sent_to_your_email: '已寄送密碼重設信件至您的信箱',
    email_address_not_found: '請輸入註冊信箱',
    wrong_login: '信箱或密碼錯誤',
    disabled_login: '該帳號已無法使用',
    set_password_failed: '密碼設定失敗',
    set_password_succeeded: '密碼設定成功',
    register_failed: '註冊失敗',
    register_succeeded: '註冊成功',
    update_failed: '更新失敗',
    update_succeeded: '更新成功',
    coming_soon: '敬請期待',
    under_maintance: '此功能正在維護當中',
    under_developement: '此功能正在開發當中',
    confirm_delete: '確定要刪除 ?',
    confirm_delete_cropperiod: '確定要刪除期作 ? 刪除後將無法再看到 & 編輯',
    confirm_done_cropperiod: '確認完成期作 ? 完成後將移動到完成期作清單，且無法再編輯。',
    confirm_done_cropperiod_2: '期作結束後，請至〈專案總覽〉查詢期作環境數據或栽培分析。',
    conform_create_cropperiod:
      '作物種類及栽種開始日期只能設定一次，請確認資訊無誤，設定後系統將自動為您提供最佳生長標準！',
    conform_info: '確認資訊無誤嗎 ?',
    confirm_this_update: '確定要更新 ?',
    enter_confirmation_code: '輸入驗證碼',
    show_charts: '顯示圖表',
    export_raw_data_to_CSV_file: '將 Raw Data 匯出成 CSV 檔案',
    unexpected_error: '發生錯誤',
    please_try_it_later: '請稍後再試',
    data_not_found: '無法取得資料',
    the_link_has_expired: '連結已失效',
    loading: '讀取中...',
    finished_to_history: '已結束，請至【 歷史資料 】頁面查看/下載裝置歷史資訊。',
    no_cropping_season_in_progress: '無進行中期作',
    copied_to_clipboard: '已複製到剪貼簿 !',
    copied_to_clipboard_failed: '複製到剪貼簿失敗 !',
    login_to_comment: '觀看/編輯留言請登入',

    upload_image_max_size: '上傳圖片的最大尺寸為2MB !!',
    map_filter_warning: '目前地圖裝置使用篩選工具過濾中，欲觀看專案全部裝置請重設篩選工具。',
    open_filter_tools: '開啟篩選工具',

    line_help: 'Line 官方說明',
    line_notify_not_showing: '綁定的群組沒有出現 Line 通知',
    plz_check_add_line_friend: '請確認是否有將官方 Line 帳號 ( @linenotify ) 加入好友',
    plz_check_add_line_to_group:
      '請確認是否有將官方 Line 帳號 ( @linenotify ) 加入綁定通知的群組內',
    project_line_binded_count: '一個專案可以綁定幾個 Line 通知群組 ?',
    a_project_a_line_group: '一個，同專案綁定到其他 Line 通知群組後，會取消跟原本群組的連結',
    line_contact: 'Line 通知的內容有哪些 ?',
    detected_stress_alerts: '偵測到的逆境警告',
    detected_device_alerts: '偵測到的裝置異常資訊',
    public_warning: '公告事項',
    service_binding_info: '服務綁定相關資訊',
    how_to_unlink_this_service: '如何取消綁定此 Line 通知服務 ?',
    plz_press_the_button_to_unlink: '按此解除此專案的 Line 通知服務',
    browser_does_not_support:
      '您的瀏覽器不支援此服務，請嘗試使用 chrome, firefox 或更新的版本開啟。',
    plz_input_alert_message: '請輸入警示通知訊息',
    alert_custom_rule_info:
      '注意：email 通知與 line 通知皆會以此規則下去計算，但不會顯示在即時監測儀表板。',
  },
};
