import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  Normal
 *  ----------------- */

export const api_Get_Shipments = (query = undefined) => request('get', `/shipments${setQueryString(query)}`);

export const api_Get_Shipment_Detail = (params) => request('get', `/shipments/${params.shipmentID}`);
export const api_Create_Shipment = (data) => request('post', '/shipments', data);
export const api_Update_Shipment = (params, data) => request('put', `/shipments/${params.shipmentID}`, data);
export const api_Delete_Shipment = (params) => request('delete', `/shipments/${params.shipmentID}`);

export const api_Update_Shipment_SUIDs = (params, data) => request('put', `/shipments/${params.shipmentID}/service_units`, data);
export const api_Delete_Shipment_Pic = (params) => request('delete', `/shipments/${params.shipmentID}/pic`);

export const api_Store_Shipment_Pic = (params, data) => {
  const _formData = new FormData();
  _formData.append('pic', data.pic);

  const _config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return request('post', `/shipments/${params.shipmentID}/pic`, _formData, _config);
};
