

const DeviceData = () => import('@/views/data/Data_Device')
const CameraData = () => import('@/views/data/Data_Camera')
const GatewayData = () => import('@/views/data/Data_Gateway')
const AccessPointData = () => import('@/views/data/Data_AccessPoint')

export default [
    {
        path: 'data/device/:hashID',
        name: 'Device Data',
        component: DeviceData,
    },
    {
        path: 'data/camera/:hashID',
        name: 'Camera Data',
        component: CameraData,
    },
    {
        path: 'data/gateway/:hashID',
        name: 'Gateway Data',
        component: GatewayData,
    },
    {
        path: 'data/accesspoint/:hashID',
        name: 'AccessPoint Data',
        component: AccessPointData,
    },
]