import request from './_https';

/** --------------------
 *  EXAMPLE
 *  --------------------
 *  export const apiFunctionName = () => request('get',      'api路徑')
 *  export const apiFunctionName = () => request('delete',   'api路徑')
 *  export const apiFunctionName = () => request('post',     'api路徑', data)
 *  export const apiFunctionName = () => request('put',      'api路徑', data)
 */

/** --------------------
 *  Normal
 *  ----------------- */

export const api_Get_Device_List_MaxID = () => request('get', '/management/last_ids');
export const api_Check_HashID_Exist = (data) => request('post', '/management/check_id', data); // 可檢測：Hashid, Type-ID, SUID
export const api_Check_CustomerID_Exist = (data) => request('post', '/management/check_customer_id', data);

/** --------------------
 *  Version
 *  ----------------- */
export const api_Get_Backend_Version = () => request('get', '/version');
