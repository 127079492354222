

const ListRecord = () => import('@/views/record/List_Record')
const EditRecord = () => import('@/views/record/Edit_Record')

export default [
    {
        path: 'records',
        redirect: '/records/list',
        name: 'Records',
        component: {
            render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'list',
                name: 'Record List',
                component: ListRecord
            },
            {
                path: 'edit',
                name: 'Record New',
                component: EditRecord
            },
            {
                path: 'edit/:recordID',
                name: 'Record Edit',
                component: EditRecord
            },
        ]
    },
]