import request from './_https';
// querystring 在 node 14 後已經棄用，改為 URLSearchParams
import setQueryString from '../tools/helpers';

/** --------------------
 *  SUID
 *  ----------------- */

export const api_Get_ServiceUnits = (query = undefined) => request('get', `/service_units${setQueryString(query)}`);
export const api_Get_ServiceUnit = (params) => request('get', `/service_units/${params.serviceunitID}`);

export const api_Batch_Create_ServiceUnit = (data) => request('post', '/admin/service_units', data);
export const api_Batch_Update_ServiceUnit = (data) => request('put', '/admin/service_units', data);

export const api_Delete_ServiceUnit = (params) => request('delete', `/admin/service_units/${params.serviceunitID}`);
export const api_Update_ServiceUnit = (params, data) => request('put', `/admin/service_units/${params.serviceunitID}`, data);
export const api_Update_ServiceUnit_Hashid = (params, data) => request('put', `/admin/service_units/${params.serviceunitID}/hashid`, data);
export const api_Update_ServiceUnit_Alias_SUID = (params, data) => request('put', `/admin/service_units/${params.serviceunitID}/alias_suid`, data);

/** --------------------
 *  SUID Log
 *  ----------------- */

export const api_Get_ServiceUnit_Logs = (query = undefined) => request('get', `/suid_logs${setQueryString(query)}`);
