

const AdminHome = () => import('@/views/admin/AdminHome')
const Develope  = () => import('@/views/admin/develope/Develope')

export default [
    {
        path: 'lord',
        redirect: '/lord/home',
        name: 'Admin',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'home',
                name: 'Admin Home',
                component: AdminHome
            },
            {
                path: 'develope',
                name: 'Develope',
                component: Develope
            },
        ]
    },
]