

const UserProfile = () => import('@/views/me/Profile')
const UserSettings = () => import('@/views/me/Settings')

export default [
    {
        path: 'me',
        redirect: '/me/profile',
        name: 'Me',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'profile',
                name: 'User Profile',
                component: UserProfile
            },
            {
                path: 'settings',
                name: 'User Settings',
                component: UserSettings
            },
        ]
    },
]