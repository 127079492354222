
import ui from './ui'
import date from './date'
import data from './data'
import input from './input'
import style from './style'
import unit from './unit'
import number from './number'
import language from './language'
import service_show from './service_show'

export default {
    data: data,
    date:　date,
    input: input,
    style: style,
    num: number,
    unit: unit,
    ui: ui,
    lang: language,
    service: service_show,
}