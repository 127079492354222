
import * as ManagementAPI from '@/apis/management'

// initial state
const state = {
    
    
}
    
   

// getters
const getters = {
  
}

// actions
const actions = {

    get_List_MaxId (context)
    {
        return new Promise((resolve, reject) => {

            ManagementAPI.api_Get_Device_List_MaxID()
                .then( res => {
                    let resData = res.data

                    if ( resData )
                    {
                        let _reorder_types = {
                            FA: undefined,
                            FB: undefined,
                            DA: undefined,
                            CB: undefined,
                            WC: undefined,
                            WD: undefined,
                            WE: undefined,
                            GW: undefined,
                            AA: undefined,
                        }

                        for (let _typeKey in res.data.max_ids)
                        {
                            _reorder_types[ _typeKey ] = res.data.max_ids[ _typeKey ]
                        }

                        res.data.max_ids = _reorder_types
                    }
                    
                    
                    resolve(resData);
                })
                .catch( err => {
                    reject(err);
                })
        })
    },

}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}