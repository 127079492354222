

const ListDevice = () => import('@/views/device/List_Device')
const EditDevice = () => import('@/views/device/Edit_Device')
const DetailDevice = () => import('@/views/device/Detail_Device')
const EditParamSets = () => import('@/views/device/Edit_ParamSets')

export default [
    {
        path: 'devices',
        redirect: '/devices/list',
        name: 'Devices',
        component: {
        render (c) { return c('router-view') }
        },
        children: [
            {
                path: 'list',
                name: 'Device List',
                component: ListDevice
            },
            {
                path: 'edit',
                name: 'Device New',
                component: EditDevice
            },
            {
                path: 'edit/:hashID',
                name: 'Device Edit',
                component: EditDevice
            },
            {
                path: 'detail/:hashID',
                name: 'Device Detail',
                component: DetailDevice
            },
            {
                path: 'manage/paramsets',
                name: 'ParamSets Edit',
                component: EditParamSets,
            },
        ]
    },
]